* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

@font-face {
  font-family: 'Cinta Light';
  src: url('CintaLight.ttf');
}

@font-face {
  font-family: 'Cinta Regular';
  src: url('CintaRegular.ttf');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

html,
body {
  margin: 0px;
  height: 100%;
}

/* Loading screen css */
.loading-screen {
  height: 100vh;
  background-color: #1c1d21;
}

.title-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.sweet-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh / 7);
}
 
.number-field {
  background: white;
}

.title {
  color: #FFFFFF;
  font-family: 'Cinta Regular', sans-serif;
  font-size: 50px;
  font-weight: bolder;
  letter-spacing: 2px;
  margin-top: calc(100vh / 4);
  margin-bottom: 0.01%;
}

.slogan {
  color: #FFFFFF;
  font-family: 'Cinta Light', sans-serif;
  font-weight: bolder;
  font-size: 25px;
  letter-spacing: 3px;
}

/* Main screen css*/
.main-screen {
  /* height: 100vh; */
  background-color: #1c1d21;
  margin: 0;
  padding: 0;
}

/* Navigation */
.nav-bar {
  height: 125px;
  border-bottom: 2px solid #00c39a;
}

.nav-bar ul li {
  float: left;
  margin-top: 50px;
  color: #FFFFFF;
  font-family: 'Cinta Regular', sans-serif;
  list-style: none;
  text-align: right;
  display: block;

}

.nav-bar ul li a {
  color: white;
  padding: 15px;
  text-transform: uppercase;
  text-decoration: none;
}

.nav-bar ul li a:hover {
  color: #00c39a;
}

.nav-bar ul li:nth-child(1),
.nav-bar ul li:nth-child(2) {
  float: left;
  color: white;
}

.nav-bar ul li:nth-child(3),
.nav-bar ul li:nth-child(4) {
  float: right;
  color: white;
}

.ring {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 8px solid #00c39a;
  position: absolute;
  left: 50%;
  margin-left: -40px;
  /* margin-top: 10px; */
}

.ring1 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 8px solid #00c39a;
  /* position: relative; */
  /* left: 50%;
  margin-left: -30px; */
  position: relative;
  left: 100%;
  /* margin-top: 20px; */
}

.ring2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 8px solid #00c39a;
  /* align-items: center; */
  position: relative;
  left: 100%;
  /* margin-left: -20px; */
  /* margin-top: 30px; */
}

/* Content section */
.mat-footer-row,
.mat-header-row,
.mat-row {
  display: inline-flex;
  min-width: 100%;
}


/* @media only screen and (max-width: 600px) {
  table {
    width: 100%;
  }
  td {
    display: block;
    width: 100%;
  }
} */
/* .content {

  display: grid;
  grid-template-columns: 500px 1fr;
  grid-template-rows: auto auto;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
  gap: 1rem;
} */
/* 
@media (min-width: 600px) {
  .content { grid-template-columns: repeat(2, 1fr); }
} */
/* .table {
  width:'95%';
  display: 'block';
  overflowX: 'auto'
  } */

/* .table-overall {
  background-color: rgb(224, 162, 19); 
  margin: 5px;
  padding: 5px;
  height: 1px;
  border-radius: 30px;
  margin-top: 1%;
} */

/* .table-tournament {
  width: 750px;
  background-color: rgb(74, 19, 224);
  margin: 0;
  height: 1px;
  border-radius: 30px;
  margin-top: 1%;
} */

/* .table-matchups {
  overflow-x: "auto";
} */

.table-matchups-captains {
  width: 100vw;
  min-width: "800px";
  overflow-x: "auto";
}


/* @media (min-width: 600px) {
  .content {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
} */

 /* .table-matchups-captains {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 3;
}

.table-matchups {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 5;
}   */


/* .table-title {
  padding-top: 5px;
  height: 115px;
  color: white;
  font-family: 'Cinta Regular', sans-serif;
  padding-left: 10px;
  font-size: 50px;
  font-weight: bolder;
  text-underline-offset: 5px;
} */